import React, { useEffect, useCallback, useState } from "react"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { useSpring, animated as a } from "react-spring"
import { useMediaQuery } from "react-responsive"
import { useInView } from "react-intersection-observer"

import Prompt from "./shared/Prompt"
import AvatarWrapper from "./avatars/AvatarWrapper"
import Navigation from "./models/Navigation"

const Wrapper = styled.div`
  height: 225vw;
  max-width: 100%;

  @media (orientation: portrait) {
    height: 200vh;
    /* height: calc(var(--vh, 1vh) * 200); */
  }
`

const Inner = styled.div`
  height: 100vh;
  /* height: calc(var(--vh, 1vh) * 100); */
  position: sticky;
  top: 0;
  max-width: 100%;
  overflow: hidden;
`

const Slider = styled(a.div)`
  width: 200vw;
  display: flex;
  will-change: transform;
`

const PromptWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
`

const NavWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 100%;
  transform: translate(-50%);
  z-index: 1;

  @media (orientation: portrait) {
    display: none;
  }
`

let off3

const Avatars = ({ setOpenModel, offset, allModels }) => {
  const [{ st }, set] = useSpring(() => ({ st: 0 }))
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const [fromTop, setFromTop] = useState(null)
  const [mobStyle, setMobStyle] = useState(null)
  const [dtStyle, setDtStyle] = useState(null)
  const [showAltMess, setShowAltMess] = useState(false)
  const [currDot, setCurrDot] = useState(-1)

  const { ref, inView, entry } = useInView()

  const onScroll = useCallback(
    e => {
      window.requestAnimationFrame(() => {
        let winHeight =
          typeof window !== "undefined" ? window.innerHeight : 1000
        let winWidth = typeof window !== "undefined" ? window.innerWidth : 1000
        if (isPortrait) {
          let off = fromTop ? fromTop : winHeight * 6.75

          let off2 = fromTop ? fromTop + winHeight * 0.65 : winHeight * 7.41

          off3 = window.scrollY

          if (window.scrollY - off2 <= 0 && inView) {
            setMobStyle(
              `translateX(${
                (winWidth / 4 -
                  (window.scrollY - off > 0
                    ? window.scrollY - off2 > 0
                      ? off3 - off
                      : window.scrollY - off
                    : 0)) *
                0.7
              }px)`
            )
          }
        } else {
          let off = fromTop
            ? fromTop
            : isPortrait
            ? winHeight * 6.75
            : winHeight * 7.4
          let off2 = fromTop
            ? isPortrait
              ? fromTop + winHeight * 0.65
              : fromTop + winWidth + 75
            : isPortrait
            ? winHeight * 7.41
            : winHeight * 9.29

          if (window.scrollY - off2 <= 0) {
            off3 = window.scrollY
          }

          // return `translateX(${
          //   ((isPortrait ? winWidth / 4 : winWidth / 2.3) -
          //     (window.scrollY - off > 0 ? (window.scrollY - off2 > 0 ? off3 - off : window.scrollY - off) : 0)) *
          //   0.7
          // }px)`

          if (window.scrollY - off2 <= 0 && inView) {
            setDtStyle(
              `translateX(${
                ((isPortrait ? winWidth / 4 : winWidth / 2.3) -
                  (window.scrollY - off > 0
                    ? window.scrollY - off2 > 0
                      ? off3 - off
                      : window.scrollY - off
                    : 0)) *
                0.7
              }px)`
            )
          }

          set({ st: window.scrollY })
        }
      })
    },
    [fromTop]
  )

  const transformStyle = st.to(o => {
    // let off = isPortrait ? window.innerHeight * 6.75 : window.innerHeight * 7.4
    //     let off2 = isPortrait
    // ? window.innerHeight * 7.41
    // : window.innerHeight * 9.29

    let winHeight = typeof window !== "undefined" ? window.innerHeight : 1000
    let winWidth = typeof window !== "undefined" ? window.innerWidth : 1000

    let off = fromTop
      ? fromTop
      : isPortrait
      ? winHeight * 6.75
      : winHeight * 7.4
    let off2 = fromTop
      ? isPortrait
        ? fromTop + winHeight * 0.65
        : fromTop + winWidth + 75
      : isPortrait
      ? winHeight * 7.41
      : winHeight * 9.29

    if (o - off2 <= 0) {
      off3 = o
    }

    return `translateX(${
      ((isPortrait ? winWidth / 4 : winWidth / 2.3) -
        (o - off > 0 ? (o - off2 > 0 ? off3 - off : o - off) : 0)) *
      0.7
    }px)`
  })

  // const transformStyleMob = st.to(o => {
  //   let off = fromTop ? fromTop : window.innerHeight * 6.75

  //   let off2 = fromTop
  //     ? fromTop + window.innerHeight * 0.65
  //     : window.innerHeight * 7.41

  //   if (window.scrollY - off2 <= 0) {
  //     off3 = window.scrollY
  //   }
  //   return `translateX(${
  //     (window.innerWidth / 4 -
  //       (window.scrollY - off > 0
  //         ? window.scrollY - off2 > 0
  //           ? off3 - off
  //           : window.scrollY - off
  //         : 0)) *
  //     0.7
  //   }px)`
  // })

  // st.to(o => {
  //   // let off = isPortrait ? window.innerHeight * 6.75 : window.innerHeight * 7.4
  //   //     let off2 = isPortrait
  //   // ? window.innerHeight * 7.41
  //   // : window.innerHeight * 9.29
  //   let off = fromTop
  //     ? fromTop
  //     : isPortrait
  //     ? window.innerHeight * 6.75
  //     : window.innerHeight * 7.4
  //   let off2 = fromTop
  //     ? isPortrait
  //       ? fromTop + window.innerHeight * 0.65
  //       : fromTop + window.innerWidth + 75
  //     : isPortrait
  //     ? window.innerHeight * 7.41
  //     : window.innerHeight * 9.29

  //   if (o - off2 <= 0) {
  //     off3 = o
  //   }

  //   return `translateX(${
  //     ((isPortrait ? window.innerWidth / 4 : window.innerWidth / 2.3) -
  //       (o - off > 0 ? (o - off2 > 0 ? off3 - off : o - off) : 0)) *
  //     0.7
  //   }px)`
  // })

  useEffect(() => {
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [isPortrait, fromTop])

  useEffect(() => {
    if (fromTop === null) {
      if (entry) {
        setFromTop(window.scrollY + window.innerHeight)
      }
    }
  }, [inView])

  return (
    <Wrapper ref={ref}>
      <Inner>
        <Slider style={{ transform: isPortrait ? mobStyle : dtStyle }}>
          <AvatarWrapper
            ind={0}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
          >
            <StaticImage
              backgroundColor={"transparent"}
              src="../images/av-1.png"
              alt="Avatar 1"
              placeholder="blurred"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(0)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={1}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
          >
            <StaticImage
              backgroundColor={"transparent"}
              src="../images/av-2.png"
              alt="Avatar 2"
              placeholder="blurred"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(1)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={2}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
          >
            <StaticImage
              backgroundColor={"transparent"}
              src="../images/av-3.png"
              alt="Avatar 3"
              placeholder="blurred"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(2)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={3}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
          >
            <StaticImage
              backgroundColor={"transparent"}
              src="../images/av-4.png"
              alt="Avatar 4"
              placeholder="blurred"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(3)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={4}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
          >
            <StaticImage
              backgroundColor={"transparent"}
              src="../images/av-5.png"
              alt="Avatar 5"
              placeholder="blurred"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(4)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
          <AvatarWrapper
            ind={5}
            offset={offset}
            setShowAltMess={setShowAltMess}
            setCurrDot={setCurrDot}
          >
            <StaticImage
              backgroundColor={"transparent"}
              src="../images/av-6.png"
              alt="Avatar 6"
              placeholder="blurred"
              onClick={() => {
                setShowAltMess(false)
                setOpenModel(5)
              }}
              onMouseEnter={() => setShowAltMess(true)}
              onMouseLeave={() => setShowAltMess(false)}
            />
          </AvatarWrapper>
        </Slider>
        <PromptWrapper>
          <Prompt>
            {showAltMess ? (
              <Trans>Explore avatar</Trans>
            ) : (
              <Trans>Scroll to browse avatars</Trans>
            )}
          </Prompt>
        </PromptWrapper>

        <NavWrapper>
          <Navigation allModels={allModels} setOpenModel={setOpenModel} currDot={currDot} />
        </NavWrapper>
      </Inner>
    </Wrapper>
  )
}

export default Avatars
