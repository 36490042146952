import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  .gatsby-image-wrapper {
    position: absolute;

    &:last-of-type {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.15s ease-in-out;
    }
  }

  @media (hover: hover) {
    &:hover {
      .gatsby-image-wrapper {
        &:last-of-type {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
`

const ImageWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default ImageWrapper
