import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { useCookies } from "react-cookie"
import { Trans, I18nextContext } from "gatsby-plugin-react-i18next"

const Wrapper = styled.div`
  font-size: 10px;
  line-height: 14px;
  position: fixed;
  bottom: 22px;
  left: 22px;
  max-width: 320px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: opacity 0.15s ease-in-out;

  @media (orientation: portrait) {
    bottom: auto;
    top: 40px;
    left: 20px;
  }
`
const CloseButton = styled.button`
  text-decoration: underline;

  @media (orientation: portrait) {
    margin-bottom: 15px;
  }
`

const Text = styled.div`
  a {
    text-decoration: underline;
  }
`

const CookieNotice = () => {
  const [cookies, setCookie] = useCookies(["dc-cn"])
  const [show, setShow] = useState(false)
  const context = useContext(I18nextContext)

  const handleCloseClick = () => {
    setShow(false)
    setCookie("dc-cn", 1, {
      path: "/",
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    })
  }

  useEffect(() => {
    if (cookies["dc-cn"]) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [])

  return (
    <Wrapper show={show}>
      <CloseButton onClick={handleCloseClick}>
        <Trans>Close</Trans>
      </CloseButton>
      {context.language === "en" && (
        <Text>
          This site uses{" "}
          <a
            href={"https://www.reserved.com/gb/en/privacy-policy-cookies"}
            target="_blank"
          >
            cookies
          </a>{" "}
          in order to provide services properly as well for statistical and
          commercial purposes. More information about our{" "}
          <a
            href={"https://www.reserved.com/gb/en/privacy-policy"}
            target="_blank"
          >
            Privacy Policy
          </a>
          .
        </Text>
      )}
      {context.language === "pl" && (
        <Text>
          Serwis korzysta z plików{" "}
          <a
            href={"https://www.reserved.com/pl/pl/privacy-policy-cookies"}
            target="_blank"
          >
            cookies
          </a>{" "}
          w celu prawidłowego świadczenia usług, jak również w celach
          statystycznych oraz reklamowych. Więcej informacji w naszej{" "}
          <a
            href={"https://www.reserved.com/pl/pl/privacy-policy"}
            target="_blank"
          >
            Polityce prywatności
          </a>
          .
        </Text>
      )}
      {context.language === "ru" && (
        <Text>
          Этот веб-сайт использует cookie-файлы в целях надлежащего
          предоставления сервисов, а также для сбора статистики и коммерческих
          целей. Дополнительные сведения о нашей Политике конфиденциальности.
        </Text>
      )}
    </Wrapper>
  )
}

export default CookieNotice
