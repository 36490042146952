import React, { useRef, useEffect, useCallback } from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"

const Wrapper = styled.div`
  width: calc(100vw / 7);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  flex-shrink: 0;
  will-change: transform;
  pointer-events: none;

  @media (orientation: portrait) {
    width: calc(100vw / 5);
    margin-top: -50px;
  }
`

const Inner = styled.div`
  height: 90vh;
  height: calc(var(--vh, 1vh) * 90);
  width: 20vw;

  @media (orientation: portrait) {
    width: 75vw;
  }

  .gatsby-image-wrapper {
    will-change: transform;
    pointer-events: auto;
    position: relative;
    object-position: center;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 28px;
      width: 38px;
      background-image: url("./dc_eye.svg");
      background-size: contain;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }

    @media (hover: hover) {
      transition: margin 0.15s ease-in-out;
      &:hover {
        margin-top: -10px;
        &:after {
          opacity: 1;
        }
      }
    }
  }

  div {
    /* height: 100%;
    width: 100%;
    object-fit: contain !important; */
  }

  /* img,
  picture {
    height: 100vh;
    width: 30vh;
    cursor: pointer;
  } */
`

const AvatarWrapper = ({ children, setCurrDot, ind }) => {
  const ref = useRef(null)
  const refInner = useRef(null)
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })

  // const updateScrollY = () => {
  //   // set({ st: window.scrollY })
  //   // translateX(${(refInner.current.getBoundingClientRect().left -
  //   //   window.innerWidth / 2) * 2}px)

  //   ref.current.querySelector(".gatsby-image-wrapper").style.transform = `
  //   translateX(${
  //     refInner.current.getBoundingClientRect().left - window.innerWidth / 5
  //   }px)

  //     scale(${
  //       0.5 +
  //       (-1 *
  //         (refInner.current.getBoundingClientRect().left -
  //           window.innerWidth / 2)) /
  //         3000
  //     })
  //   `
  // }

  const onScroll = useCallback(e => {
    window.requestAnimationFrame(() => {
      ref.current.querySelector(".gatsby-image-wrapper").style.transform = `
    translateX(${
      refInner.current.getBoundingClientRect().left - window.innerWidth / 5
    }px)
      
      scale(${
        0.5 +
        (-1 *
          (refInner.current.getBoundingClientRect().left -
            window.innerWidth / 2)) /
          3000
      })
    `
    })
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return (
    <Wrapper ref={ref}>
      <Inner ref={refInner}>{children}</Inner>
    </Wrapper>
  )
}

export default AvatarWrapper
