import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO41 = () => {

  return (
    <StaticImage
      src="../../images/JPG-FEMALE-LOOK004-1.jpg"
      title="Reserved avatar"
      alt="Reserved avatar"

    />
  )
}

export default ImageMO41
