import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"

const Wrapper = styled.footer`
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;

  font-size: 12px;
  padding: 8px 22px;

  display: flex;
  justify-content: space-between;

  @media (orientation: portrait) {
    flex-direction: column;
    line-height: 20px;
    bottom: 15px;
  }
`

const Text = styled.div`
  display: flex;

  @media (orientation: portrait) {
    /* display: ${({ ar }) => (ar ? "none" : "flex")}; */
    flex-direction: column;
    width: 100%;
    text-align: ${({ center }) => (center ? "center" : "left")};
    margin-bottom: ${({ ar }) => (ar ? "75px" : "0")};
  }
`

const ExternalLink = styled.a`
  margin-left: 14px;
  position: relative;

  @media (orientation: portrait) {
    text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
    margin-left: ${({ underline }) => (underline ? "7px" : "0")};
    margin-right: 7px;
    order: ${({ order }) => order};
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background-color: #fff;

    @media (orientation: portrait) {
      display: none;
    }
  }
`

const Footer = ({ setShowLightImage }) => {
  const { ref, inView, entry } = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (inView) {
      setShowLightImage(true)
    } else {
      setShowLightImage(false)
    }
  }, [inView])

  return (
    <Wrapper ref={ref}>
      <Text ar center>
        <Text center>Discover Meta AR Effect on:</Text>
        <ExternalLink
          href={"https://instagram.com"}
          target={"_blank"}
          underline
        >
          Instagram
        </ExternalLink>
        <ExternalLink href={"https://facebook.com"} target={"_blank"} underline>
          Facebook Messenger
        </ExternalLink>
      </Text>

      <Text>
        <ExternalLink href={"https://reserved.com"} target={"_blank"} order={3}>
          Reserved Instagram
        </ExternalLink>
        <ExternalLink href={"https://cookies.com"} target={"_blank"} order={1}>
          Cookies
        </ExternalLink>
        <ExternalLink href={"https://privacy.com"} target={"_blank"} order={2}>
          Privacy policy
        </ExternalLink>
      </Text>
    </Wrapper>
  )
}

export default Footer
