import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"

import ImageLB1 from "../staticImages/ImageLB1"
import ImageLB2 from "../staticImages/ImageLB2"
import ImageLB3 from "../staticImages/ImageLB3"
import ImageLB4 from "../staticImages/ImageLB4"
import ImageLB5 from "../staticImages/ImageLB5"
import ImageLB6 from "../staticImages/ImageLB6"
import ImageLB7 from "../staticImages/ImageLB7"
import ImageLB8 from "../staticImages/ImageLB8"
import ImageLB9 from "../staticImages/ImageLB9"
import ImageLB10 from "../staticImages/ImageLB10"
import ImageLB11 from "../staticImages/ImageLB11"
import ImageLB12 from "../staticImages/ImageLB12"
import ImageLB13 from "../staticImages/ImageLB13"
import ImageLB14 from "../staticImages/ImageLB14"
import ImageLB15 from "../staticImages/ImageLB15"
import ImageLB16 from "../staticImages/ImageLB16"
import ImageLB17 from "../staticImages/ImageLB17"
import ImageLB18 from "../staticImages/ImageLB18"
import ImageLB19 from "../staticImages/ImageLB19"
import ImageLB20 from "../staticImages/ImageLB20"

import ImageLB1m from "../staticImages/ImageLB1m"
import ImageLB2m from "../staticImages/ImageLB2m"
import ImageLB3m from "../staticImages/ImageLB3m"
import ImageLB4m from "../staticImages/ImageLB4m"
import ImageLB5m from "../staticImages/ImageLB5m"
import ImageLB6m from "../staticImages/ImageLB6m"
import ImageLB7m from "../staticImages/ImageLB7m"
import ImageLB8m from "../staticImages/ImageLB8m"
import ImageLB9m from "../staticImages/ImageLB9m"
import ImageLB10m from "../staticImages/ImageLB10m"
import ImageLB11m from "../staticImages/ImageLB11m"
import ImageLB12m from "../staticImages/ImageLB12m"
import ImageLB13m from "../staticImages/ImageLB13m"
import ImageLB14m from "../staticImages/ImageLB14m"
import ImageLB15m from "../staticImages/ImageLB15m"
import ImageLB16m from "../staticImages/ImageLB16m"
import ImageLB17m from "../staticImages/ImageLB17m"
import ImageLB18m from "../staticImages/ImageLB18m"
import ImageLB19m from "../staticImages/ImageLB19m"
import ImageLB20m from "../staticImages/ImageLB20m"


const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: auto;
  padding-right: 100px;
  display: block;

  &:last-of-type {
    padding-right: 0;
  }

  @media (orientation: portrait) {
    height: auto;
    padding-right: 0;
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  margin-top: ${({ size }) =>
    size === "2" ? "10vh" : size === "3" ? "25vh" : "0"};

  height: ${({ size }) =>
    size === "2" ? "80vh " : size === "3" ? "50vh" : "100vh"};

  width: ${({ size, ratio }) =>
    size === "2"
      ? `${80 * ratio}vh`
      : size === "3"
      ? `${50 * ratio}vh`
      : `${100 * ratio}vh`};
  overflow: hidden;

  @media (orientation: portrait) {
    margin: 0;
    height: auto;
    width: 100%;
    margin-bottom: 10px;

    margin-left: ${({ size }) =>
      size === "2" ? "20px" : size === "3" ? "60px" : "0"};

    width: ${({ size }) =>
      size === "2"
        ? "calc(100% - 40px)"
        : size === "3"
        ? "calc(100% - 120px)"
        : "100%"};
  }

  .gatsby-image-wrapper.full-h {
    height: 100% !important;
    width: 100% !important;

    @media (orientation: portrait) {
      width: 100% !important;
      height: auto !important;
    }
  }
`

const Dot = styled.button`
  height: 54px;
  width: 54px;
  border-radius: 50%;
  padding: 20px;

  position: absolute;
  top: ${({ posY }) => posY};
  left: ${({ posX }) => posX};

  span {
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 50%;
    display: block;
    pointer-events: none;

    @media (orientation: portrait) {
      display: none;
    }
  }

  @media (hover: hover) {
    transition: padding 0.2s ease-in-out;

    &:hover {
      padding: 18px;
    }
  }

  @media (orientation: portrait) {
    height: 32px;
    width: 32px;
    background: transparent;
    border: 2px solid #fff;
  }
`

const Item = ({ data, first, setOpenProduct }) => {
  const wrapperEl = useRef(null)
  const [ratio, setRatio] = useState(1)
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })

  useEffect(() => {
    if (wrapperEl.current) {
      const gatsbyImage = wrapperEl.current.querySelector(
        ".gatsby-image-wrapper"
      )

      if (gatsbyImage) {
        setRatio(
          parseInt(gatsbyImage.style.width) / parseInt(gatsbyImage.style.height)
        )

        gatsbyImage.classList.add("full-h")
      }
    }
  }, [wrapperEl.current])

  return (
    <Wrapper ref={wrapperEl}>
      <ImageWrapper size={data.size || 1} ratio={ratio}>
        {
          {
            1: isPortrait ? <ImageLB1m /> : <ImageLB1 />,
            2: isPortrait ? <ImageLB2m /> : <ImageLB2 />,
            3: isPortrait ? <ImageLB3m /> : <ImageLB3 />,
            4: isPortrait ? <ImageLB4m /> : <ImageLB4 />,
            5: isPortrait ? <ImageLB5m /> : <ImageLB5 />,
            6: isPortrait ? <ImageLB6m /> : <ImageLB6 />,
            7: isPortrait ? <ImageLB7m /> : <ImageLB7 />,
            8: isPortrait ? <ImageLB8m /> : <ImageLB8 />,
            9: isPortrait ? <ImageLB9m /> : <ImageLB9 />,
            10: isPortrait ? <ImageLB10m /> : <ImageLB10 />,
            11: isPortrait ? <ImageLB11m /> : <ImageLB11 />,
            12: isPortrait ? <ImageLB12m /> : <ImageLB12 />,
            13: isPortrait ? <ImageLB13m /> : <ImageLB13 />,
            14: isPortrait ? <ImageLB14m /> : <ImageLB14 />,
            15: isPortrait ? <ImageLB15m /> : <ImageLB15 />,
            16: isPortrait ? <ImageLB16m /> : <ImageLB16 />,
            17: isPortrait ? <ImageLB17m /> : <ImageLB17 />,
            18: isPortrait ? <ImageLB18m /> : <ImageLB18 />,
            19: isPortrait ? <ImageLB19m /> : <ImageLB19 />,
            20: isPortrait ? <ImageLB20m /> : <ImageLB20 />,
          }[data.image_id]
        }

        {data.hotspots &&
          data.hotspots.map((item, i) => {
            return (
              <Dot
                type="button"
                key={item.hs_id}
                posX={item.posX}
                posY={item.posY}
                onClick={() => {
                  setOpenProduct(item.product_id)
                }}
              >
                <span></span>
              </Dot>
            )
          })}
      </ImageWrapper>
    </Wrapper>
  )
}

export default Item
