import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;

  @media (orientation: portrait) {
    flex-direction: column-reverse;
  }
`

const Text = styled.div``

const Icon = styled.div`
  svg {
    height: 22px;
    width: 22px;
    margin-bottom: 25px;

    @media (orientation: portrait) {
      height: 24px;
      width: 24px;
      margin-top: 24px;
    }
  }
`

const Prompt = ({ children, icon, handleClick }) => {
  return (
    <Wrapper onClick={handleClick && handleClick}>
      {icon && (
        <Icon>
          {
            {
              rotate: (
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 94.8 94.8"
                >
                  <path
                    d="M93.3,47.4c0-12.8-20.1-22.9-45.8-22.9l3.3-2.5c0.9-0.7,1.1-2,0.4-2.9c-0.7-0.9-2-1.1-2.9-0.4l-8.3,6.2l-0.4,0.5
	c-0.3,0.6-0.3,1.2,0,1.8l0.4,0.5l8.3,6.3c0.4,0.3,0.8,0.4,1.2,0.4c0.7,0,1.3-0.3,1.7-0.8c0.7-0.9,0.5-2.2-0.4-2.9l-3.3-1.9
	c22.6,0,41.7,8.6,41.7,18.8c0,6.7-8.3,12.5-20.2,16c1-5.3,1.4-10.6,1.4-16l2.5,3.3h0c0.3,0.5,0.8,0.9,1.4,1c0.6,0.1,1.2,0,1.7-0.4
	c0.5-0.4,0.8-0.9,0.8-1.5c0-0.6-0.2-1.2-0.6-1.6l-6.2-8.3l-0.5-0.4h-0.2c-0.6-0.3-1.2-0.3-1.8,0h-0.2l-0.5,0.4l-6.2,8.3h0
	c-0.7,0.9-0.5,2.2,0.4,2.9c0.4,0.3,0.8,0.4,1.2,0.4c0.7,0,1.3-0.3,1.7-0.8l2.5-3.3c0,5.7-0.6,11.4-1.7,17
	c-5.7,1.2-11.4,1.7-17.2,1.7c-5.7,0-11.4-0.6-17-1.7c-1.1-5.6-1.7-11.3-1.7-17c0-22.6,8.6-41.7,18.8-41.7c4.7,0,9.2,4.2,12.7,11.3v0
	c0.5,1,1.8,1.5,2.8,1s1.5-1.8,1-2.8C59.6,6.5,53.8,1.6,47.4,1.6c-12.8,0-22.9,20.1-22.9,45.8c0,5.4,0.5,10.7,1.4,16
	c-11.8-3.5-20.2-9.3-20.2-16c0-4.7,4.2-9.2,11.3-12.5c1-0.5,1.5-1.8,1-2.8c-0.5-1-1.8-1.5-2.8-1C6.5,35.2,1.6,41,1.6,47.4
	c0,9.1,10.2,16.7,25.3,20.5C30.8,83,38.3,93.3,47.4,93.3S64.1,83,68,68C83,64.1,93.3,56.5,93.3,47.4L93.3,47.4z M47.4,89.1
	c-6.7,0-12.5-8.3-16-20.2c5.3,1,10.6,1.4,16,1.4c5.4,0,10.7-0.5,16-1.4C59.9,80.8,54.1,89.1,47.4,89.1z"
                  />
                </svg>
              ),
              arrows: (
                <svg
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 96.2 18.5"
                >
                  <polygon
                    points="86.9,17.2 84.8,15.1 89.3,10.6 6.9,10.6 11.4,15.1 9.3,17.2 1.2,9.1 9.3,1 11.4,3.1 6.9,7.6 89.3,7.6 84.8,3.1 
	86.9,1 95.1,9.1 "
                  />
                </svg>
              ),
              index: (
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 10 17"
                >
                  <circle id="Oval" className="st0" cx="1.5" cy="1.5" r="1.5" />
                  <circle
                    id="Oval-Copy"
                    className="st0"
                    cx="8.5"
                    cy="1.5"
                    r="1.5"
                  />
                  <circle
                    id="Oval-Copy-2"
                    className="st0"
                    cx="1.5"
                    cy="8.5"
                    r="1.5"
                  />
                  <circle
                    id="Oval-Copy-3"
                    className="st0"
                    cx="8.5"
                    cy="8.5"
                    r="1.5"
                  />
                  <circle
                    id="Oval-Copy-4"
                    className="st0"
                    cx="8.5"
                    cy="15.5"
                    r="1.5"
                  />
                  <circle
                    id="Oval-Copy-5"
                    className="st0"
                    cx="1.5"
                    cy="15.5"
                    r="1.5"
                  />
                </svg>
              ),
              scroll: (
                <svg
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 49.7 80.7"
                >
                  <g>
                    <path
                      d="M48,52V30.1c0-5.4,0.4-10.6-1.8-15.8C39.9-0.6,18.7-2.8,7.5,8.2C-1,16.6,1.6,31.7,1.6,42.5c0,6.3-0.2,12.7,0,19
		c0.5,11,11.5,18.3,21.7,18.7c0.3,0,0.7,0,0.9-0.1c9.1-0.5,19.3-4.5,22.7-13.6C48.7,62.1,48,56.7,48,52z M40.6,61
		c-0.6,8.4-9.9,11.6-17.1,11.8c0,0,0,0,0,0c0,0,0,0,0,0c-7-0.3-14.1-5.1-14.2-12.5c-0.1-4.7,0-9.5,0-14.2V28.7c0-3.2-0.4-7,0.5-10.1
		c3.5-11.8,23.3-13.4,29.1-2.9c2.2,3.9,1.9,7.7,1.9,12v17.7C40.6,50.6,40.9,55.9,40.6,61z"
                    />
                    <path d="M21.4,24.9v9c0,4.8,7.5,4.8,7.5,0v-9C28.9,20.1,21.4,20,21.4,24.9z" />
                  </g>
                </svg>
              ),
            }[icon]
          }
        </Icon>
      )}
      <Text>{children}</Text>
    </Wrapper>
  )
}

export default Prompt
