import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import lax from "lax.js"

const Wrapper = styled.div`
  height: 200vh;
  height: calc(var(--vh, 1vh) * 200);
  display: flex;
  justify-content: center;
  padding: 60px 22px;
  padding-top: 15vh;
  
  
  @media (orientation: portrait) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    align-items: center;
  }
`
const ImageWrapper = styled.div`
  max-width: 100vh;

  @media (orientation: portrait) {
    max-width: 100%;
  }
`

const ObjectMob = ({ setShowLoader, inView }) => {
  const wrapperEl = useRef(null)
  useEffect(() => {

    
    setShowLoader(false)

    // console.log('test')
    // lax.addElements(".top-welcome", {
    //   scrollY: {
    //     scale: [
    //       ["0", "screenHeight"],
    //       ["1", "4"],
    //     ],
    //   },
    // })
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (inView) {
        const scrolled = (1 + window.scrollY / 1000).toFixed(4)
        const transformValue = "scale(" + scrolled + ")"

        wrapperEl.current.style.WebkitTransform = transformValue
        wrapperEl.current.style.MozTransform = transformValue
        wrapperEl.current.style.OTransform = transformValue
        wrapperEl.current.style.transform = transformValue
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [wrapperEl.current, inView])

  return (
    <Wrapper>
      <ImageWrapper className={"top-welcome"} ref={wrapperEl}>
        <StaticImage
          src="../../images/w2dc.png"
          alt="Welcome 2 Dreamcore"
          placeholder="blurred"
          backgroundColor={"transparent"}
        />
      </ImageWrapper>
    </Wrapper>
  )
}

export default ObjectMob
