import React, { Suspense, useRef, useEffect, useCallback } from "react"
import { withPrefix } from "gatsby"
import * as THREE from "three"
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber"
import { useMediaQuery } from "react-responsive"
import ImageLB9 from "./staticImages/ImageLB9"

// import Model from "./DcNew"

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"

import {
  useGLTF,
  Environment,
  // Loader,
  OrbitControls,
  useHelper,
  useProgress,
  Html,
} from "@react-three/drei"
import { useControls } from "leva"
import { EffectComposer } from "@react-three/postprocessing"
import { a, useTransition } from "@react-spring/three"
import { useSpring, config } from "@react-spring/core"
import { useGesture } from "react-use-gesture"
import Scroll from "./top/Scroll"

import { DirectionalLightHelper, PropertyBinding } from "three"

function Loader() {
  const { progress } = useProgress()

  return <Html center>{progress} % loaded</Html>
}

function Suzi(props) {
  // const { nodes, materials } = useGLTF(
  //   `${withPrefix("/")}dc3d2.glb`
  // )
  // const { nodes, materials } = useGLTF(`${withPrefix("/")}dc-new-2.glb`)
  const { nodes, materials } = useGLTF(`${withPrefix("/")}w2dc-2.glb`)
  // const { nodes, materials } = useGLTF(`${withPrefix("/")}dc-3.glb`)
  // const { nodes, materials } = useGLTF(
  //   `${withPrefix("/")}WELCOME_2_DREAMCORE.glb`
  // )
  // const { nodes, materials } = useGLTF(
  //   `${withPrefix("/")}but_szary.glb`

  // )
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  // const { nodes } = useGLTF("./WELCOME_2_DREAMCORE.glb")
  const ref = useRef()
  const [y] = Scroll(
    [0, typeof window !== "undefined" ? window.innerHeight : 1000],
    { domTarget: window },
    props.inView
  )

  // const texture = useLoader(
  //   THREE.TextureLoader,
  //   `${withPrefix("/")}dc-texture.png`
  //   // `${withPrefix("/")}napis__Out1_0040.png`
  // )
  // const texture = useLoader(THREE.TextureLoader, `${withPrefix("/")}model-1-mini.jpg`)

  // const materialProps = useControls({
  //   // thickness: { value: 20, min: 0, max: 20 },
  //   roughness: { value: 1, min: 0, max: 1, step: 0.1 },
  //   // clearcoat: { value: 0, min: 0, max: 1, step: 0.1 },
  //   // clearcoatRoughness: { value: 0, min: 0, max: 1, step: 0.1 },
  //   // transmission: { value: 1, min: 0.9, max: 1, step: 0.01 },
  //   // ior: { value: 2, min: 1, max: 2.3, step: 0.05 },
  //   // envMapIntensity: { value: 25, min: 0, max: 100, step: 1 },
  //   // color: "rgba(180, 155, 177, 1)",
  //   // attenuationTint: "rgba(180, 155, 177, 1)",
  //   // attenuationDistance: { value: 0, min: 0, max: 1 },
  // })

  const propsTwo = useControls({
    "position-z": { value: 1, min: 0, max: 1000, step: 1 },
  })

  // return (
  //   <a.mesh
  //     geometry={nodes["razem"].geometry}
  //     {...props}
  //     // rotation-x={Math.PI }
  //     rotation-x={y.to(y => (y / (isPortrait ? 4000 : 2000)) * Math.PI)}
  //     position={[0, 20, 0]}
  //     position-z={y.to(y => (y / (isPortrait ? 3000 : 1000)) * 100)}
  //     position-y={y.to(y => (y * -1) / 55 + (isPortrait ? 0 : 20))}
  //     ref={ref}
  //     // position-y={y.to((y) => (y / 500) * 5)}
  //   >
  //     <meshPhysicalMaterial
  //     // {...materialProps}
  //     attach="material" map={texture} />
  //   </a.mesh>
  // )

  useEffect(() => {
    props.setShowLoader(false)
  }, [])

  // return (
  //   <group
  //     dispose={null}
  //     rotation-x={y.to(y => (y / (isPortrait ? 4000 : 2000)) * Math.PI + 90)}
  //     position-z={y.to(y => (y / (isPortrait ? 3000 : 1000)) * 100)}
  //     position-y={y.to(y => (y * -1) / 55 + (isPortrait ? 0 : 20))}
  //     ref={ref}
  //   >
  //     <mesh geometry={nodes.amCore.geometry} material={nodes.amCore.material} />
  //     <mesh geometry={nodes.Welco.geometry} material={nodes.Welco.material} />
  //     <mesh
  //       geometry={nodes.Me2Dre001.geometry}
  //       material={nodes.Me2Dre001.material}
  //     />
  //   </group>
  // )

  return (
    <a.mesh
      // geometry={nodes["razem"].geometry}
      geometry={nodes.Welco.geometry}
      material={nodes.Welco.material}
      // geometry={nodes["napis_obj"].geometry}
      {...props}
      // {...propsTwo}
      // position={[0, 20, 0]}
      // rotation-x={y.to(y => (y / (isPortrait ? 4000 : 2000)) * Math.PI + 90)}
      rotation-x={
        !isPortrait && y.to(y => (y / (isPortrait ? 4000 : 2000)) * Math.PI)
      }
      position-y={
        !isPortrait && y.to(y => (y * -1) / 55 + (isPortrait ? 0 : 23))
      }
      position-z={
        !isPortrait && y.to(y => (y / (isPortrait ? 3000 : 1000)) * 100)
      }
      ref={ref}
      scale={[80, 80, 80]}
      // position-y={y.to((y) => (y / 500) * 5)}
    >
      {
        // <meshPhysicalMaterial
        // {...materialProps}
        // attach="material"
        // attach="material"
        // color="yellow"
        // map={texture}
        // />
      }
    </a.mesh>
  )
}

const Rig = ({ inView }) => {
  const { camera, mouse } = useThree()

  const vec = new THREE.Vector3()
  return useFrame(
    () =>
      inView &&
      camera.position.lerp(
        vec.set(mouse.x * 5, mouse.y * 3, camera.position.z),
        0.1
      )
  )
}

// function Camera(props) {
//   const ref = useRef()
//   const set = useThree((state) => state.set);

//   const [y] = Scroll([0, 500], { domTarget: window });

//   // Make the camera known to the system
//   useEffect(() => void set({ camera: ref.current }), []);
//   // Update it every frame
//   useFrame(() => ref.current.updateMatrixWorld())
//   return <a.perspectiveCamera ref={ref} {...props} position-y={y.to((y) => (y / 500) * 100)} />
// }

const Light = ({ col, intensity }) => {
  const ref = useRef()
  // useHelper(ref, DirectionalLightHelper, 1)
  return (
    <directionalLight
      ref={ref}
      intensity={1}
      position={[10, 10, 10]}
      shadow-mapSize-width={2048}
      shadow-mapSize-height={2048}
      color={"blue"}
      castShadow
    />
  )
}
const Glass = ({ inView, setShowLoader }) => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const light1Props = useControls({
    roughness: { value: 0.2, min: 0, max: 1, step: 0.1 },
    position: [0, 100, -100],
    intensity: { value: 0.5, min: 0, max: 1 },
    color: "rgba(180, 155, 177, 1)",
  })

  return (
    <React.Fragment>
      <Canvas
        dpr={[1, 2]}
        camera={{ position: [0, 0, isPortrait ? 120 : 60] }}
        gl={{ alpha: false }}
      >
        <color attach="background" args={["#f2f2f2"]} />
        <Suspense fallback={null}>
          <Suzi inView={inView} setShowLoader={setShowLoader} />
        </Suspense>
        <ambientLight color={"white"} intensity={1} />
        {
          // <ambientLight color={"rgba(180, 155, 177, 1)"} intensity={0.7} />
          // <directionalLight
          //   intensity={0.5}
          //   position={[10, 10, 100]}
          //   color={"pink"}
          //   castShadow
          // />
          // <directionalLight
          //   intensity={0.6}
          //   position={[10, -50, 1]}
          //   color={"teal"}
          //   castShadow
          // />
          // <directionalLight
          //   intensity={0.5}
          //   position={[10, 10, 1]}
          //   color={"blue"}
          //   castShadow
          // />
        }
        <Rig inView={inView} />
        {
          // <Suspense fallback={null}>
          // <Environment
          //   files={`${withPrefix("/")}adams_place_bridge_1k.hdr`}
          //   preset={"sunset"}
          // />
          // </Suspense>
          // <pointLight {...light1Props} />
          // <rectAreaLight
          //   width={10}
          //   height={10}
          //   color={'rgba(180, 155, 177, 1)'}
          //   intensity={100}
          //   position={[0, 20, 50]}
          //   lookAt={[0, -25, 0]}
          //   penumbra={1}
          //   // castShadow
          // />
          // <Camera position={[0, 0, 25]} />
          // <OrbitControls enableZoom={true} />
        }
      </Canvas>
      {
        // <Loader />
      }
    </React.Fragment>
  )
}

export default Glass
