import React, { useEffect } from "react"
import styled from "styled-components"
import lax from "lax.js"
import { useMediaQuery } from "react-responsive"
import { Trans } from "gatsby-plugin-react-i18next"

import lb from "../../content/lookbook.json"

import Item from "./lookbook/Item"
import Heading from "./Heading"

const Wrapper = styled.div`
  height: 800vh;
  height: calc(var(--vh, 1vh) * 800);
  position: relative;
  max-width: 100%;
  z-index: 99;

  @media (orientation: portrait) {
    height: auto;
  }
`
const StickyContainer = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: sticky;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  background-color: #f2f2f2;

  @media (orientation: portrait) {
    height: auto;
    position: relative;
  }
`

const HeadingWrapper = styled.div`
  position: absolute;
  top: calc(50vh - 70px);
  top: calc(var(--vh, 1vh) * 50 - 70px);
  width: 100%;
  color: #f2f2f2;
  mix-blend-mode: difference;
  z-index: 1;

  transition: opacity 0.25s ease-out;

  @media (orientation: portrait) {
    display: none;
  }

  &.dc-hide {
    opacity: 0;
    pointer-events: none;
  }
  /* height: 100vh; */
`

const Inner = styled.div`
  display: flex;
  width: auto;
  position: absolute;
  padding-left: 90vw;

  @media (orientation: portrait) {
    flex-direction: column;
    padding: 0;
    position: relative;
  }
`

if (typeof window !== "undefined") {
  lax.init()

  lax.addDriver("scrollY", function () {
    return window.scrollY
  })
}

const Lookbook = ({ setOpenProduct }) => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  useEffect(() => {
    if (!isPortrait) {
      lax.addElements(".dc-collection", {
        scrollY: {
          translateX: [
            ["screenHeight", "screenHeight*7"],
            ["0", "-elWidth"],
          ],
        },
      })

      lax.addElements(
        ".dc-coll-heading",
        {},
        {
          onUpdate: (driverValues, domElement) => {
            if (driverValues.scrollY[0] > window.innerHeight * 1.25) {
              domElement.classList.add("dc-hide")
              document.body.classList.remove("dc-top")
            } else {
              domElement.classList.remove("dc-hide")
              document.body.classList.add("dc-top")
            }
          },
        }
      )
    }
  }, [isPortrait])

  // console.log(document.querySelector('.dc-collection')?.offsetWidth)

  useEffect(() => {
    window.addEventListener("scroll", () => setOpenProduct(-1))

    return () => {
      window.removeEventListener("scroll", () => setOpenProduct(-1))
    }
  }, [])

  return (
    <Wrapper>
      <StickyContainer>
        <HeadingWrapper className={"dc-coll-heading"}>
          <Heading speed={15}>
            <Trans>Discover the collection</Trans>
          </Heading>
        </HeadingWrapper>
        <Inner className={"dc-collection"}>
          {lb &&
            lb.items &&
            lb.items.map((item, i) => {
              return (
                <Item
                  data={item}
                  key={item.title + i}
                  first={i === 0}
                  setOpenProduct={setOpenProduct}
                ></Item>
              )
            })}
        </Inner>
      </StickyContainer>
    </Wrapper>
  )
}

export default Lookbook
