import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO43 = () => {

  return (
    <StaticImage
      src="../../images/JPG-FEMALE-LOOK004-3.jpg"
      title="Reserved avatar"
      alt="Reserved avatar"

    />
  )
}

export default ImageMO43
