import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO63 = () => {

  return (
    <StaticImage
      src="../../images/JPG-FEMALE-LOOK006-3.jpg"
      title="Reserved avatar"
      alt="Reserved avatar"
    />
  )
}

export default ImageMO63
