import React, { useEffect, useState, useCallback } from "react"
import styled, {keyframes} from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"
import { useInView } from "react-intersection-observer"
import { useMediaQuery } from "react-responsive"

import { detect } from "detect-browser"

import ObjectMob from "./top/ObjectMob"

import Glass from "./Glass"

const Wrapper = styled.div`
  height: 200vh;
  height: calc(var(--vh, 1vh) * 200);
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background-color: #f2f2f2;
  max-width: 100%;
  overflow: hidden;

  @media (orientation: portrait) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  canvas {
    background-color: #f2f2f2;
  }
`

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  /* 60% {
    transform: translateY(-5px);
  } */
`

const Heading = styled.h1`
  position: absolute;
  top: calc(100vh - 98px);
  top: calc(var(--vh, 1vh) * 100 - 98px);
  font-size: 16px;
  width: 100%;
  text-align: center;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: opacity 0.15s ease-out;

  @media (orientation: portrait) {
    font-size: 14px;
  }

  svg {
    height: 8px;
    width: 14px;
    display: block;
    margin: 30px auto 0;
    animation: ${bounce} 2s ease-in-out infinite;
  }
`

const Top = ({ setShowLoader }) => {
  const [showHeading, setShowHeading] = useState(true)
  const { ref, inView } = useInView()
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const [isSafari14, setIsSafari14] = useState(true)

  const handleScroll = useCallback(e => {
    window.requestAnimationFrame(() => {
      if (window.scrollY <= 0) {
        setShowHeading(true)
      } else {
        setShowHeading(false)
      }
    })
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    const browser = detect()

    if (browser.name.toLowerCase() === "safari") {
      setIsSafari14(true)
    } else {
      setIsSafari14(false)
    }

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Wrapper ref={ref}>
      {isPortrait || isSafari14 ? (
        <ObjectMob inView={inView} setShowLoader={setShowLoader} />
      ) : (
        <Glass inView={inView} setShowLoader={setShowLoader} />
      )}

      <Heading show={showHeading}>
        <Trans>Reserved Spring 2022 Collection</Trans>

        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 7.84324L13.6341 1.22506L12.7909 0.381876L7 6.15688L1.225 0.365967L0.365906 1.22506L7 7.84324Z"
            fill="black"
          />
        </svg>
      </Heading>
    </Wrapper>
  )
}

export default Top
