import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"

const Wrapper = styled.div``

const Anchor = ({ name, setShow }) => {
  const { ref, inView, entry } = useInView()

  useEffect(() => {
    if (entry) {
      if (entry.boundingClientRect.top < 0) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [inView])

  return <Wrapper ref={ref} id={name}></Wrapper>
}

export default Anchor
