import React, { useRef, useState } from "react"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"
import { useMediaQuery } from "react-responsive"

import HoverCard from "./video/HoverCard"
import Player from "./video/Player"

const Wrapper = styled.div`
  height: 50vw;
  width: 100%;
  position: relative;
  margin-bottom: ${({ marginB }) => marginB ? '80px' : '0'};

  @media (orientation: portrait) {
    height: 130vw;
  }
`

const Inside = styled.div`
  height: 42vw;
  width: 75vw;

  @media (orientation: portrait) {
    height: 112vw;
    width: 90vw;
  }
`

const PlayButtonWrapper = styled.div`
  position: sticky;
  top: 20vw;
  margin-bottom: 10vw;
  display: flex;
  justify-content: center;
  z-index: 99;
  pointer-events: none;

  @media (orientation: portrait) {
    top: 30vh;
    top: calc(var(--vh, 1vh) * 30);
    margin-bottom: 15vw;
  }
`

const PlayButton = styled.button`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  color: #fff;
  padding: 19px 22px;
  margin-top: 10vh;
  margin-top: calc(var(--vh, 1vh) * 10);
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: opacity 0.25s ease-in-out;
`

const Video = ({ btnText, btnTextMob, srcDt, srcMob, posterMob, posterDt, marginB }) => {
  const playEl = useRef(null)
  const [showButton, setShowButton] = useState(true)
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })

  return (
    <Wrapper marginB={marginB}>
      <PlayButtonWrapper>
        <PlayButton
          show={showButton}
          onClick={() => {
            if (playEl.current) {
              playEl.current.click()
            }
          }}
        >
          {isPortrait ? <Trans>{btnTextMob}</Trans> : <Trans>{btnText}</Trans>}
        </PlayButton>
      </PlayButtonWrapper>
      <HoverCard>
        <Inside>
          {isPortrait ? (
            <Player
              showVideo={true}
              innerRefPlayEl={playEl}
              setShowButton={setShowButton}
              src={srcMob}
              poster={posterMob}
              ></Player>
              ) : (
                <Player
                showVideo={true}
                innerRefPlayEl={playEl}
                setShowButton={setShowButton}
                src={srcDt}
                poster={posterDt}
            ></Player>
          )}
        </Inside>
      </HoverCard>
    </Wrapper>
  )
}

export default Video
