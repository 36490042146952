import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import { useClickAway } from "react-use"
import { Trans } from "gatsby-plugin-react-i18next"
import { I18nextContext } from "gatsby-plugin-react-i18next"

import P1 from "./products/images/P1"
import P2 from "./products/images/P2"
import P3 from "./products/images/P3"
import P4 from "./products/images/P4"
import P5 from "./products/images/P5"
import P6 from "./products/images/P6"
import P7 from "./products/images/P7"
import P8 from "./products/images/P8"
import P9 from "./products/images/P9"
import P10 from "./products/images/P10"
import P11 from "./products/images/P11"
import P12 from "./products/images/P12"
import P13 from "./products/images/P13"
import P14 from "./products/images/P14"
import P15 from "./products/images/P15"
import P16 from "./products/images/P16"
import P17 from "./products/images/P17"
import P18 from "./products/images/P18"
import P19 from "./products/images/P19"
import P20 from "./products/images/P20"
import P21 from "./products/images/P21"
import P22 from "./products/images/P22"
import P23 from "./products/images/P23"
import P24 from "./products/images/P24"
import P25 from "./products/images/P25"
import P26 from "./products/images/P26"
import P27 from "./products/images/P27"
import P28 from "./products/images/P28"
import P29 from "./products/images/P29"

const Wrapper = styled.div`
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: opacity 0.15s ease-out;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999999;

  transform: translate(-50%, -50%);

  display: ${({ show }) => (show ? "block" : "none")};
  @media (orientation: portrait) {
  }
`
const Main = styled.div`
  background-color: #f2f2f2;
  height: 415px;
  width: 315px;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
`

const Close = styled.button`
  position: absolute;
  top: 20px;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
  color: #fff;
  mix-blend-mode: difference;
  padding: 0 20px;
`

const Name = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  font-size: 14px;
  z-index: 1;
  color: #fff;
  mix-blend-mode: difference;
  padding: 0 20px;
  pointer-events: none;
  width: 100%;
  text-align: center;
`

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  height: 90px;
  background-color: #fff;
  border-radius: 14px;

  font-size: 14px;

  svg {
    height: 14px;
    width: 12px;
    margin-left: 12px;
  }
`

const Product = ({ data, show, setOpenProduct, marketId, market }) => {
  const ref = useRef(null)
  const [buyLink, setBuyLink] = useState(null)

  useEffect(() => {
    if (marketId && market) {
      fetch(
        // `https://arch.reserved.com/api/${marketId}/product/products/5636M-04X`
        `https://arch.reserved.com/api/${marketId}/product/products/${data.url}`
      )
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          // if (resultData.products && resultData.products.length > 0) {
          //   // "https://www.reserved.com/pl/pl/5636m-04x/kamizelka-k-bjt",
          //   const marketSlug = market.replace("_", "/")
          //   setBuyLink(`https://www.reserved.com/${marketSlug}/${data.url}/`)
          // } else {
            //   setBuyLink(false)
            // }
              const marketSlug = market.replace("_", "/")
          setBuyLink(`https://www.reserved.com/${marketSlug}/${data.url}/`)
        }) // set data for the number of stars
    }
  }, [marketId, market])

  useClickAway(ref, () => {
    setOpenProduct(-1)
  })

  return (
    <Wrapper ref={ref} show={show}>
      <Main>
        <Close>
          <Trans>Close</Trans>
        </Close>
        {
          {
            1: <P1 />,
            2: <P2 />,
            3: <P3 />,
            4: <P4 />,
            5: <P5 />,
            6: <P6 />,
            7: <P7 />,
            8: <P8 />,
            9: <P9 />,
            10: <P10 />,
            11: <P11 />,
            12: <P12 />,
            13: <P13 />,
            14: <P14 />,
            15: <P15 />,
            16: <P16 />,
            17: <P17 />,
            18: <P18 />,
            19: <P19 />,
            20: <P20 />,
            21: <P21 />,
            22: <P22 />,
            23: <P23 />,
            24: <P24 />,
            25: <P25 />,
            26: <P26 />,
            27: <P27 />,
            28: <P28 />,
            29: <P29 />,
          }[data.id]
        }
        <Name>
          <Trans>{data.title}</Trans>
        </Name>
      </Main>

      <Button href={buyLink} target="_blank" disabled={!buyLink}>
        {!buyLink ? <Trans>Coming soon</Trans> : <Trans>Buy product</Trans>}
        {buyLink && (
          <svg
            width="100pt"
            height="100pt"
            version="1.1"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m7.6875 5.0039c-1.5352 0.16016-2.6953 1.457-2.6875 3v83.996c0 1.6562 1.3438 3 3 3h84c1.6562 0 3-1.3438 3-3v-42 0.003906c0.011719-0.80469-0.30078-1.5781-0.86328-2.1523-0.5625-0.57031-1.332-0.89062-2.1367-0.89062s-1.5742 0.32031-2.1367 0.89062c-0.5625 0.57422-0.875 1.3477-0.86328 2.1523v39h-78v-77.996h39v-0.003906c0.80469 0.011719 1.5781-0.30078 2.1484-0.86328 0.57422-0.5625 0.89453-1.332 0.89453-2.1367 0-0.80469-0.32031-1.5742-0.89453-2.1367-0.57031-0.5625-1.3438-0.875-2.1484-0.86328h-42c-0.10547-0.003906-0.20703-0.003906-0.3125 0zm58 0c-1.6562 0.085938-2.9297 1.5-2.8438 3.1562s1.5 2.9297 3.1562 2.8438h18.75l-42.875 42.871c-1.1719 1.1758-1.168 3.0781 0.003906 4.25s3.0703 1.1719 4.2461 0l42.875-42.871v18.75c-0.011719 0.80078 0.30078 1.5742 0.86328 2.1484 0.5625 0.57031 1.332 0.89453 2.1367 0.89453s1.5742-0.32422 2.1367-0.89453c0.5625-0.57422 0.875-1.3477 0.86328-2.1484v-26c0-1.6562-1.3438-3-3-3h-26c-0.10547-0.003906-0.20703-0.003906-0.3125 0z" />
          </svg>
        )}
      </Button>
    </Wrapper>
  )
}

export default Product
