import React, { useState } from "react"
import styled from "styled-components"
import { I18nextContext } from "gatsby-plugin-react-i18next"
import CookieNotice from "./CookieNotice"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  padding: 8px 22px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (orientation: portrait) {
    padding: 8px 20px;
  }

  svg {
    height: 14px;
    width: 110px;

    path,
    circle {
      transition: fill 0.25s ease-in-out;

      .dc-dark & {
        fill: #fff;
      }
    }
  }
`

const TextWrapper = styled.div`
  position: relative;

  span {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;

    .dc-top & {
      opacity: 0;
      pointer-events: none;
    }

    @media (orientation: portrait) {
      display: none;
    }
  }
`

const LangSwitch = styled.button`
  font-size: 12px;
  text-transform: capitalize;
  margin-bottom: 8px;
  margin-left: 10px;
`

const Dropdown = styled.ul`
  position: absolute;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;

  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: 0.25s ease-in-out;

  z-index: 2;
`

const DDItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    display: inline-block;
    padding-bottom: 8px;
  }
`

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 1;
  pointer-events: none;
`

const Icon = styled.button`
  margin-bottom: 10px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  transition: opacity 0.15s ease-in-out;

  svg {
    height: 18px;
    width: 18px;
  }
`

const Header = ({ showLb, showAv, showVid, market }) => {
  const context = React.useContext(I18nextContext)
  const [showLang, setShowLang] = useState(false)

  const handleIconClick = id => {
    document.querySelector(`#${id}`).scrollIntoView()
  }

  return (
    <Wrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 765.18512 84.22237"
        height="84.222374"
        width="765.18512"
        version="1.1"
        id="svg3336"
      >
        <defs id="defs3340">
          <clipPath id="clipPath3366" clipPathUnits="userSpaceOnUse">
            <path
              id="path3368"
              d="m 0,841.89 1190.551,0 0,-841.89 L 0,0 0,841.89 Z"
            />
          </clipPath>
        </defs>
        <g transform="matrix(1.25,0,0,-1.25,-361.50125,568.29225)" id="g3344">
          <g transform="translate(715.3633,387.2559)" id="g3346">
            <path
              id="path3348"
              d="m 0,0 -2.357,0 -33.1,66.487 14.568,0 19.92,-42.118 0.153,0 19.892,42.118 14.104,0 L 0,0 Z"
            />
          </g>
          <g transform="translate(417.6904,453.751)" id="g3350">
            <path
              id="path3352"
              d="m 0,0 0,-10.854 -32.023,0 0,-16.377 29.925,0 0,-10.457 -29.925,0 0,-16.656 34.948,0 0,-10.851 -47.623,0 0,65.195 L 0,0 Z"
            />
          </g>
          <g transform="translate(574.8379,453.751)" id="g3354">
            <path
              id="path3356"
              d="m 0,0 0,-10.854 -32.015,0 0,-16.847 29.917,0 0,-10.455 -29.917,0 0,-16.188 34.933,0 0,-10.851 -47.615,0 0,65.195 L 0,0 Z"
            />
          </g>
          <g transform="translate(815.2305,453.751)" id="g3358">
            <path
              id="path3360"
              d="m 0,0 0,-10.854 -32.016,0 0,-16.981 29.922,0 0,-10.456 -29.922,0 0,-16.053 34.944,0 0,-10.851 -47.625,0 0,65.195 L 0,0 Z"
            />
          </g>
          <g id="g3362">
            <g clipPath="url(#clipPath3366)" id="g3364">
              <g transform="translate(845.7031,388.5557)" id="g3370">
                <path
                  id="path3372"
                  d="m 0,0 0,65.195 22.143,0 c 22.156,0 33.503,-13.497 33.503,-32.598 C 55.646,13.495 44.299,0 22.143,0 L 0,0 Z m 22.143,10.693 c 14.408,0 20.9,7.258 20.9,21.904 0,14.647 -6.492,21.942 -20.9,21.942 l -9.467,0 0,-43.846 9.467,0 z"
                />
              </g>
              <g transform="translate(498.29,446.8018)" id="g3374">
                <path
                  id="path3376"
                  d="m 0,0 -5.976,-8.314 c -5.309,3.47 -10.922,5.472 -17.791,5.472 -9.468,0 -12.282,-3.625 -12.282,-7.056 0,-11.655 37.971,-4.882 37.971,-27.933 0,-12.579 -10.87,-21.321 -26.232,-21.321 -10.66,0 -19.852,3.295 -27.33,11.7 l 6.827,7.916 c 6.281,-5.89 12.571,-8.871 20.701,-8.871 9.451,0 14.101,4.536 14.101,9.503 0,13.951 -38.95,4.543 -38.95,28.353 0,10.453 10.476,18.383 25.361,18.383 C -14.012,7.832 -5.172,4.456 0,0"
                />
              </g>
              <g transform="translate(315.917,422.3877)" id="g3378">
                <path
                  id="path3380"
                  d="m 0,0 -14.048,0 0,20.928 13.921,0 c 7.987,0 11.669,-3.907 11.669,-10.035 C 11.542,3.224 6.551,0 0,0 m 30.85,-33.605 -1.107,9.56 c -0.986,-0.207 -2.183,-0.31 -3.529,-0.31 -5.323,0 -7.991,1.628 -11.186,6.661 -1.185,1.868 -2.475,4.141 -3.577,5.924 -1.261,1.986 -2.083,2.853 -3.32,3.641 9.352,2.24 15.932,9.109 15.932,19.467 0,11.586 -8.304,20.017 -24.19,20.017 l -26.589,0 0,-65.187 12.668,0 0,23.928 5.697,0 c 3.228,0 4.693,-1.163 6.059,-3.266 1.311,-2.019 5.789,-9.432 5.789,-9.432 5.666,-8.964 11.947,-11.961 20.385,-11.961 3.038,0 5.562,0.508 6.968,0.958"
                />
              </g>
              <g transform="translate(635.6406,422.3877)" id="g3382">
                <path
                  id="path3384"
                  d="m 0,0 -14.047,0 0,20.928 13.922,0 c 7.986,0 11.666,-3.907 11.666,-10.035 C 11.541,3.224 6.551,0 0,0 m 30.85,-33.605 -1.108,9.56 c -0.986,-0.207 -2.181,-0.31 -3.529,-0.31 -5.324,0 -7.99,1.628 -11.186,6.661 -1.183,1.868 -2.476,4.141 -3.578,5.924 -1.26,1.986 -2.082,2.853 -3.318,3.641 9.351,2.24 15.932,9.109 15.932,19.467 0,11.586 -8.303,20.017 -24.188,20.017 l -26.59,0 0,-65.187 12.668,0 0,23.928 5.695,0 c 3.229,0 4.694,-1.163 6.059,-3.266 1.313,-2.019 5.789,-9.432 5.789,-9.432 5.666,-8.964 11.949,-11.961 20.385,-11.961 3.037,0 5.562,0.508 6.969,0.958"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>

      <TextWrapper>
        <span>Welcome 2 Dreamcore 2022</span>
        <LangSwitch
          onClick={() => setShowLang(!showLang)}
          onMouseEnter={() => setShowLang(true)}
        >
          {context.language}
        </LangSwitch>
        <Dropdown onMouseLeave={() => setShowLang(false)} show={showLang}>
          {context.language !== "en" && (
            <DDItem>
              <a href={`${context.originalPath}?${market}`}>En</a>
            </DDItem>
          )}

          {context.language !== "pl" && (
            <DDItem>
              <a href={`${context.originalPath}pl/?${market}`}>Pl</a>
            </DDItem>
          )}

          {context.language !== "ru" && (
            <DDItem>
              <a href={`${context.originalPath}ru/?${market}`}>Ru</a>
            </DDItem>
          )}
        </Dropdown>

        <Icons>
          <Icon
            show={showLb && !showLang}
            onClick={() => handleIconClick("lb")}
          >
            <svg
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 73.7 61"
            >
              <g>
                <path
                  d="M46.2,58.6H15C8.1,58.6,2.5,53,2.5,46.1V14.8C2.5,7.9,8.1,2.3,15,2.3h31.2c3.3,0,6.5,1.3,8.8,3.7s3.7,5.5,3.7,8.8v31.3
		c0,3.3-1.3,6.5-3.7,8.8S49.6,58.6,46.2,58.6z M15,8.6c-3.5,0-6.2,2.8-6.2,6.2v31.3c0,1.7,0.7,3.2,1.8,4.4s2.8,1.8,4.4,1.8h31.2
		c1.7,0,3.2-0.7,4.4-1.8s1.8-2.8,1.8-4.4V14.8c0-1.7-0.7-3.2-1.8-4.4s-2.7-1.8-4.4-1.8H15z"
                />
                <path
                  d="M63,57.6c-1.4,0-2.7-1-3.1-2.4c-0.3-1.4,0.3-2.9,1.6-3.5c2.1-1.1,3.4-3.2,3.4-5.6V14.9c0-2.3-1.3-4.5-3.4-5.6
		c-1.5-0.8-2.1-2.7-1.3-4.2s2.7-2.1,4.2-1.3c4.2,2.1,6.8,6.4,6.8,11.1v31.3c0,4.7-2.6,9-6.8,11.1C64,57.4,63.5,57.6,63,57.6L63,57.6
		z"
                />
                <path
                  d="M43.1,43c-0.6,0-1.1-0.2-1.6-0.4L32.1,37c-2.5-1.5-5.6-1.1-7.6,0.9l-4.2,4.2c-1.2,1.2-3.2,1.2-4.4,0s-1.2-3.2,0-4.4l4.2-4
		c4.1-4,10.3-4.8,15.2-1.9l9.4,5.6c1.1,0.8,1.5,2.1,1.2,3.4C45.6,42,44.4,42.9,43.1,43L43.1,43z"
                />
                <path d="M43.1,19.5c0,6.3-9.4,6.3-9.4,0S43.1,13.3,43.1,19.5" />
              </g>
            </svg>
          </Icon>
          <Icon
            show={showVid && !showLang}
            onClick={() => handleIconClick("vid")}
          >
            <svg
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 85.1 66.6"
            >
              <g>
                <path d="M52.5,34l-15,8.8c-0.6,0.6-1.2,0-1.2-0.6V24.6c0-0.6,0.6-1.2,1.2-0.6l15,8.8C53.2,32.7,53.2,34,52.5,34z" />
                <path
                  d="M51.3,64.6H33.8C16.3,64.6,2.5,50.8,2.5,34c0-17.5,14.4-31.9,31.3-31.9h18.1c17.5,0,31.2,14.4,31.2,31.9
		C83.2,50.8,68.8,64.6,51.3,64.6 M33.8,9C20,9,8.8,20.2,8.8,34s11.2,24.4,25,24.4h18.1c13.8,0,25-10.6,25-24.4S65.7,9,51.9,9
		C51.9,9,33.8,9,33.8,9z"
                />
              </g>
            </svg>
          </Icon>
          <Icon
            show={showAv && !showLang}
            onClick={() => handleIconClick("av")}
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 17"
            >
              <circle id="Oval" className="st0" cx="1.5" cy="1.5" r="1.5" />
              <circle
                id="Oval-Copy"
                className="st0"
                cx="8.5"
                cy="1.5"
                r="1.5"
              />
              <circle
                id="Oval-Copy-2"
                className="st0"
                cx="1.5"
                cy="8.5"
                r="1.5"
              />
              <circle
                id="Oval-Copy-3"
                className="st0"
                cx="8.5"
                cy="8.5"
                r="1.5"
              />
              <circle
                id="Oval-Copy-4"
                className="st0"
                cx="8.5"
                cy="15.5"
                r="1.5"
              />
              <circle
                id="Oval-Copy-5"
                className="st0"
                cx="1.5"
                cy="15.5"
                r="1.5"
              />
            </svg>
          </Icon>
        </Icons>
      </TextWrapper>
      <CookieNotice />
    </Wrapper>
  )
}

export default Header
