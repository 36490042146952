import React, { useRef, useEffect } from "react"
import Avatars from "./Avatars"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

import Heading from "./Heading"
import Navigation from "./models/Navigation"

const Wrapper = styled.div`
  position: relative;
  z-index: 2;

  @media (orientation: portrait) {
    padding-top: 50px;
  }
`

const HeadingWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 120px;

  @media (orientation: portrait) {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Text = styled.p`
  font-size: 16px;
  line-height: 1.4;
  padding: 0 22px 0;
  width: 100%;
  text-align: center;

  @media (orientation: portrait) {
    padding: 75px 22px 0;
    font-size: 14px;
  }
`

const SlidersWrapper = ({ setOpenModel, allModels }) => {
  const wrapperEl = useRef(null)

  return (
    <Wrapper ref={wrapperEl}>
      <HeadingWrapper>
        <Heading>
          <Trans>Step into a new realm</Trans>
        </Heading>
      </HeadingWrapper>

      <Text>
        <Trans>We invite you for an exploration</Trans>
        <br />
        <Trans>Where there are no boundaries and anything is possible.</Trans>
        <br />
        <Trans>Enjoy.</Trans>
      </Text>

      <Avatars
        setOpenModel={setOpenModel}
        offset={wrapperEl.current ? wrapperEl.current.offsetTop : 0}
        allModels={allModels}
      />
    </Wrapper>
  )
}

export default SlidersWrapper
