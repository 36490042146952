import React from "react"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

import Prompt from "../shared/Prompt"

const Wrapper = styled.div`
  display: none;

  @media (orientation: portrait) {
    display: flex;
    justify-content: space-between;
    padding: 22px;
    background-color: #f2f2f2;
    align-items: flex-start;
  }
`

const TagsButton = styled.button`
  font-size: 14px;
  text-align: left;
`

const Text = styled.span`
  display: ${({ show }) => (show ? "inline" : "none")};
`

const Icon = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};

  svg {
    height: 24px;
    width: 24px;
    margin-top: 24px;
  }
`

const RotatePrompt = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const MobileNav = ({ showTags, setShowTags, setShowIndex }) => {
  return (
    <Wrapper>
      <TagsButton onClick={() => setShowTags(!showTags)}>
        <Trans>Tags</Trans>:{" "}
        <Text show={!showTags}>
          <Trans>On</Trans>
        </Text>
        <Text show={showTags}>
          <Trans>Off</Trans>
        </Text>
        <Icon show={!showTags}>
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 73.8 73.8"
          >
            <path
              d="M67.7,37.3L36.5,6.1c-1-1.2-2.7-1.9-4.4-1.9H11.3C7.7,4.2,5,6.9,5,10.5v20.8c0,1.7,0.6,3.3,1.9,4.4l31.2,31.3
c1.2,1.2,2.9,1.9,4.4,1.9s3.1-0.6,4.4-1.9l20.8-20.8C70.2,43.8,70.2,39.6,67.7,37.3z M19.6,23c-2.3,0-4.2-1.9-4.2-4.2
s1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2S21.9,23,19.6,23z"
            />
          </svg>
        </Icon>
        <Icon show={showTags}>
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 73.8 73.8"
          >
            <path
              d="M42.4,71.4c-2.2,0-4.5-1-6.1-2.6L5,37.6c-1.7-1.5-2.7-3.7-2.7-6.2V10.6c0-4.9,3.8-8.8,8.8-8.8h20.8c2.5,0,4.7,1,6.2,2.7
   l31.1,31.1c1.7,1.6,2.6,3.8,2.6,6.2s-1,4.6-2.6,6.2L48.5,68.8C46.9,70.5,44.6,71.4,42.4,71.4z M11.1,6.9c-2.2,0-3.8,1.6-3.8,3.8
   v20.8c0,1,0.4,1.9,1,2.5l0.2,0.2l31.2,31.3c0.8,0.8,1.9,1.1,2.6,1.1s1.8-0.3,2.6-1.1l20.9-20.9c0.7-0.6,1.1-1.5,1.1-2.5
   s-0.4-1.9-1.1-2.5l-0.1-0.1L34.4,7.8c-0.5-0.6-1.4-1-2.5-1L11.1,6.9L11.1,6.9z M19.4,25.6c-3.7,0-6.7-3-6.7-6.7s3-6.7,6.7-6.7
   s6.7,3,6.7,6.7S23.1,25.6,19.4,25.6z M19.4,17.3c-0.9,0-1.7,0.7-1.7,1.7s0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7S20.4,17.3,19.4,17.3z"
            />
          </svg>
        </Icon>
      </TagsButton>

      <RotatePrompt>
        <Prompt icon={"rotate"}>
          <Trans>Rotate and explore</Trans>
        </Prompt>
      </RotatePrompt>

      <Prompt icon={"index"} handleClick={() => setShowIndex(true)}>
        <Trans>Index</Trans>
      </Prompt>
    </Wrapper>
  )
}

export default MobileNav
