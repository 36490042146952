import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageLB = () => {

  return (
    <StaticImage
      src="../../images/Reserved_SS22_LB10.jpg"
      alt="Welcome 2 Dreamcore collection"
    />
  )
}

export default ImageLB
